.accordion-header{
    color: white;
    font-size: 12px;
    cursor: pointer;
}


.CmpButtonForm{
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CmpButtonForm:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
}
.CmpButtonForm:active{
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

.RellenarAutoForm{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    border: 2px solid #38A3A5;
}
.RellenarAutoForm:hover{
    background-color: #38A3A5;
    transition: 0.2s;
}

.buttonsForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpFormPetit{
    background-color: #22577A;
    padding: 40px;
    border-radius: 10px;
    transition: 0.4s;
    width: 300px;
}

.CmpFormPetit:hover{
    background-color: #22577A;
    transition: 0.4s;
}

.CmpFormColumna{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adsense{
    width: 100%;
}

.anuncioAfiliacion{
    font-size: 15px;
    font-family: Roboto-Bold-700;
    text-align: center;
    color: #22577A;
}

@keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

/*.CamposAdicionales{
    -webkit-animation: new 0.5s;
    animation: new 0.5s;
}
*/

.ComprobarDatos{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto-Bold-700;
    color: #22577A;
}

.ComprobarDatosDatos{
    display: flex;
    padding: 5px;
}

.ComprobarDatosButtons{
    display: flex;
    justify-content: center;
}

.ComprobarDatosButtons div{
    margin-right: 10px;
}

.ComprobarDatosButton{
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 1000px){
    .ComprobarDatosButtons{
        display: flex;
        
        flex-direction: column;
        
    }
    .ComprobarDatosButtons div{
        margin-right: 0px;
        margin-bottom: 10px;
        justify-content: center;
    }
}