.CmpButton{
  font-family: Roboto-Black-900;
  background-color: #80ED99;
  cursor: pointer;
  border-radius: 3px;
  color: #22577A;
  width: 200px;
  height: 40px;
  float: right;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
  
  .CmpButton:hover{
    background-color: #65ec85;
    color: #22577A;
    transition: 0.2s;
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
  }
  .CmpButton:active{
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
  }

  .RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }