.CmpMenu a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.CmpMenu h1{
    color: #22577A;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.CmpMenu img{
    width: 50px;
}

.CmpMenu{
    align-items: center;
    padding: 20px 80px 10px 80px;
    display: flex;
    font-family: Roboto-Black-900;
    color: #22577A;
}

.CmpMenuMenu{
    display: flex;
    justify-content: center;
    width: 50%;
    font-size: 14px;
}

.CmpMenuMenu > div{
    margin-right: 50px;
    cursor: pointer;

    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(#22577A 0 0);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /*change after the size immediately*/
}

.CmpMenuMenu > div:hover{
    background-position: 100% 100%;
    background-size: 100% 2px;
}

.CmpMenuLogo{
    width: 25%;
}

.CmpMenuConsultoria{
    font-size: 14px;
    width: 25%;
    display: flex;
    justify-content: center;
}

.buttonConsultoria{
    width: 70%;
    cursor: pointer;
    border: 1px solid #38A3A5;
    padding: 8px 3px 8px 3px;
    border-radius: 4px;
    background: linear-gradient(to left, rgba(255, 0, 0, 0) 50%, #38A3A5 50%) right;
    background-size: 200%;
    transition: all 0.4s ease;
}

.buttonConsultoria:hover{
    color: white;
    background-position: left;
}

.CmpMenuTlf{
    padding: 10px 5px;
}


.HamburguerMenu{
    width: fit-content;
    right: 5px;
    position: absolute;
}

.CmpMenuLogoTlf{
    width: fit-content;
}

.HamburguerMenu{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.CmpMenuMenuTlf{
    flex-direction: column;
    font-family: Roboto-Black-900;
    font-size: 20px;
    padding-top: 30px;
    width: 100%;
}

.CmpMenuMenuTlf > div{
    padding-bottom: 15px;
}

.CmpMenuConsultoriaTlf{
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 20px;
}

.CmpMenuConsultoriaTlf > div{
    background-color: #38A3A5;
    text-align: center;
    color: white;
    font-family: Roboto-Bold-700;
    font-size: 18px;
}
