.CmpGraficaIndexada{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.CmpGraficaIndexada h2{
    color: #5EA253;
}