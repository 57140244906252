.CmpQandA{
    padding: 150px 100px 30px 100px;
    margin: auto;
    background: rgb(128,237,153);
    background: linear-gradient(0deg, rgba(128,237,153,1) 70%, rgba(0,212,255,0) 100%);
    
}

.CmpQandA h2{
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 36px;
    color: #22577A;
    font-family: Roboto-Black-900;
}

.CmpQandA h3 button{
    font-size: 20px;
    font-weight: 600;
}

.accordion-body{
    text-align: left;
}

.accordion{
    padding-left: 100px;
    padding-right: 100px;
}

.accordion-item{
    background-color: rgba(0, 0, 0, 0);
    border-style: none none solid none;
}

.accordion-item:first-of-type{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.accordion-item:last-of-type{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.accordion-item:hover{
    background-color: #38A3A5;
    transition: 0.6s;
}

.accordion-button{
    background-color: rgba(0, 0, 0, 0);
}

.accordion-button:not(.collapsed){
    background-color: #38A3A5;
    color: black;
}

.accordion-button:focus{
    color: black;
    box-shadow: none !important;
}

.accordion-body{
    background-color: #E1FFEE;
}

@media (max-width: 1000px){
    .CmpQandA{
        padding-top: 20px;
        padding-right: 0px;
        padding-left: 
        0px;
    }
    .accordion{
        padding: 0px;
    }
}