.RellenarAuto{
  font-weight: 300;
  font-size: 15px;
  width: 98px;
  height: 25px;
  margin-top: 0px;
  background-color: #d66515d6;
}
.RellenarAuto:hover{
  background-color: #d66515fe;
  transition: 0.2s;
}

.CmpTarifaDestacado{
border: 3px solid rgba(5, 170, 0, 0.68);
}

.CmpTarifa-butons{
display: flex;
padding: 0px 15px;
vertical-align: middle;
flex-direction: column;
align-items: center;
width: 240px;
}

.CmpTarifa-butons div:first-child{
margin-bottom: 5px;
}

.CmpTarifa-columna{
padding: 0px 15px;
display: table-cell;
vertical-align: middle;
border-right: 1px solid #bbb;
width: 250px;
}

.CmpTarifa-total{
font-weight: 600;
}

.CmpTarifa.true{
  background-color: #80ED99;
}

.CmpButtonContratar{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  font-size: 18px;
  font-family: Roboto-Black-900;
  background-color: #22577A;
  color: white;
  background: linear-gradient(to left, #22577A 50%, #38A3A5 50%) right;
  background-size: 200%;
  transition: all 0.4s ease;
  cursor: pointer;
}

.CmpButtonContratar:hover{
  color: white;
  background-position: left;
}

.DetalleTarifa{
  font-size: 14px;
  background-color: #80ED99;
  color: #22577A;
  margin-right: 0px;
  height: 25px;
}

.DetalleTarifa:first-child{
  margin-right: 3px;
}

.CmpTarifaDetalleButtons{
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 1000px){
.CmpTarifa{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.CmpTarifa-columna{
    border-right: 0px;
}
.CmpTarifa-total{
  font-weight: 600;
  font-size: 30px;
}
}