body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto-Regular-400';
  src: local('Roboto-Regular-400'), url(/static/media/Roboto-Regular-400.8a36205b.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-Black-900';
  src: local('Roboto-Black-900'), url(/static/media/Roboto-Black-900.d6a6f887.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto-Bold-700';
  src: local('Roboto-Bold-700'), url(/static/media/Roboto-Bold-700.b8e42971.ttf) format('truetype');
  font-weight: 700;
}
.App {
  text-align: center;
  background-color: #FCFDFF;
  height: 100vh;
  font-family: Roboto-Regular-400;
}

.HomePage{
}

.App-content {
  padding: 4rem 10%;
  padding-bottom: 0;
  padding-top: 200px;
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/*Columnes formulari*/
.CmpForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  justify-content: center;
  padding: 20px;
  width: 35%;
  position: relative;
}

/*Global del formulari*/
.CmpFormGlobal{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 350px;
  align-items: center;
  position: relative;
  border-radius: 25px;
}

.iconoFlama{
  position: absolute;
  right: 32%;
  top: -10px;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@keyframes mover {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}

@media (max-width: 1450px){

  .iconoFlama{
    position: absolute;
    right: 15%;
    top: -10px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

}


@media (max-width: 1450px){
  .CmpFormPetit{
      width: 90%;
  }

}

@media (max-width: 1000px){

  /*Pagina principal INI*/
  .App-content{
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 0;
  }

  .CmpFormGlobal{
    flex-direction: column;
    height: auto;
  }

  .CmpFormGlobal form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CmpButton{
    margin-bottom: 10px;
  }

  .CmpVideo iframe{
    width: 80%;
    max-width: 560px;
  }

  .iconoFlama {
    right: 10px;
  }
  /*Pagina principal FIN*/
}



.CmpButton{
  font-family: Roboto-Black-900;
  background-color: #80ED99;
  cursor: pointer;
  border-radius: 3px;
  color: #22577A;
  width: 200px;
  height: 40px;
  float: right;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
  
  .CmpButton:hover{
    background-color: #65ec85;
    color: #22577A;
    transition: 0.2s;
    -webkit-transform: translate(-0.05em, -0.05em);
            transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
  }
  .CmpButton:active{
    -webkit-transform: translate(0.05em, 0.05em);
            transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
  }

  .RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }
/* Input formulari */

.CmpInput input {
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
    padding: 1rem;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: text;
    height: 15px;
    display: flex;
    margin-bottom: 15px;
    background: transparent;
    color: black;
    background-color: white;
   }

   .CmpInput input::-webkit-input-placeholder{
    color: black;
   }

   .CmpInput input::placeholder{
    color: black;
   }
   
   .CmpInput input:focus {
    outline: none;
    border: 1px solid rgb(27, 0, 227);
   }
  
   .CmpInput label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
   }
  
   .CmpInputError input{
     border-color: red;
   }
 /* Select formulari */

 .CmpSelect label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
  }
  
  .CmpSelect select{
    padding: 0;
    height: 35px;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-bottom: 15px;
    background-color: white;
    color: black;
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
  }
  
  .CmpSelect select:focus{
    outline-color: #9ED996;
    border-color: #9ED996;
  }

  .CmpSelect option{
    /*background-color: rgba(5, 170, 0, 0.68);*/
    background-color: white;
    color: black;
  }

  .CmpSelectError select{
    border-color: red;
  }
.accordion-header{
    color: white;
    font-size: 12px;
    cursor: pointer;
}


.CmpButtonForm{
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CmpButtonForm:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    -webkit-transform: translate(-0.05em, -0.05em);
            transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
}
.CmpButtonForm:active{
    -webkit-transform: translate(0.05em, 0.05em);
            transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

.RellenarAutoForm{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    border: 2px solid #38A3A5;
}
.RellenarAutoForm:hover{
    background-color: #38A3A5;
    transition: 0.2s;
}

.buttonsForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpFormPetit{
    background-color: #22577A;
    padding: 40px;
    border-radius: 10px;
    transition: 0.4s;
    width: 300px;
}

.CmpFormPetit:hover{
    background-color: #22577A;
    transition: 0.4s;
}

.CmpFormColumna{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adsense{
    width: 100%;
}

.anuncioAfiliacion{
    font-size: 15px;
    font-family: Roboto-Bold-700;
    text-align: center;
    color: #22577A;
}

@-webkit-keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

@keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

/*.CamposAdicionales{
    -webkit-animation: new 0.5s;
    animation: new 0.5s;
}
*/

.ComprobarDatos{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto-Bold-700;
    color: #22577A;
}

.ComprobarDatosDatos{
    display: flex;
    padding: 5px;
}

.ComprobarDatosButtons{
    display: flex;
    justify-content: center;
}

.ComprobarDatosButtons div{
    margin-right: 10px;
}

.ComprobarDatosButton{
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 1000px){
    .ComprobarDatosButtons{
        display: flex;
        
        flex-direction: column;
        
    }
    .ComprobarDatosButtons div{
        margin-right: 0px;
        margin-bottom: 10px;
        justify-content: center;
    }
}
.CmpInfo{
    display: flex;
    flex-direction: column;
    color: rgba(51, 21, 214, 0.7);
    justify-content: center;
    min-height: 500px;
    width: 65%;
    padding: 20px;
    align-items: flex-start;
}

.CmpInfo p{
    font-size: 14px;
    font-family: Roboto-Bold-700;
    text-align: left;
    color: black;
}

.CmpInfo h1{
    font-size: 48px;
    font-family: Roboto-Bold-700;
    text-align: left;
    color: #22577A;
}

.CmpInfo h2{
    padding-top: 20px;
    font-size: 15px;
    text-align: left;
    color: white;
}

.surTitol{
    font-size: 48px;
    font-family: Roboto-Bold-700;
    text-align: left;
    margin-top: -20px;
    color: #22577A;
}

.cursorIcon{
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.clickEffect{
    position:fixed;
    box-sizing:border-box;
    border-style:solid;
    border-color:#57CC99;
    border-radius:50%;
    -webkit-animation:clickEffect 0.4s ease-out;
            animation:clickEffect 0.4s ease-out;
    z-index:99999;
    }
@-webkit-keyframes clickEffect{
    0%{
    opacity:1;
    width:0.5em; height:0.5em;
    margin:-0.25em;
    border-width:0.5em;
    }
    100%{
    opacity:0.2;
    width:15em; height:15em;
    margin:-7.5em;
    border-width:0.03em;
    }
}
@keyframes clickEffect{
    0%{
    opacity:1;
    width:0.5em; height:0.5em;
    margin:-0.25em;
    border-width:0.5em;
    }
    100%{
    opacity:0.2;
    width:15em; height:15em;
    margin:-7.5em;
    border-width:0.03em;
    }
}

@media (max-width: 1000px){
    .CmpInfo{
        width: 100%;
        align-items: center;
        padding-bottom: 50px;
        min-height: auto;
        padding-top: 100px;
    }
}
.heightMax{
    height: 150px;
}
.CmpGraficaIndexada{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.CmpGraficaIndexada h2{
    color: #5EA253;
}
.CmpMenu a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.CmpMenu h1{
    color: #22577A;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.CmpMenu img{
    width: 50px;
}

.CmpMenu{
    align-items: center;
    padding: 20px 80px 10px 80px;
    display: flex;
    font-family: Roboto-Black-900;
    color: #22577A;
}

.CmpMenuMenu{
    display: flex;
    justify-content: center;
    width: 50%;
    font-size: 14px;
}

.CmpMenuMenu > div{
    margin-right: 50px;
    cursor: pointer;

    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(#22577A 0 0);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /*change after the size immediately*/
}

.CmpMenuMenu > div:hover{
    background-position: 100% 100%;
    background-size: 100% 2px;
}

.CmpMenuLogo{
    width: 25%;
}

.CmpMenuConsultoria{
    font-size: 14px;
    width: 25%;
    display: flex;
    justify-content: center;
}

.buttonConsultoria{
    width: 70%;
    cursor: pointer;
    border: 1px solid #38A3A5;
    padding: 8px 3px 8px 3px;
    border-radius: 4px;
    background: linear-gradient(to left, rgba(255, 0, 0, 0) 50%, #38A3A5 50%) right;
    background-size: 200%;
    transition: all 0.4s ease;
}

.buttonConsultoria:hover{
    color: white;
    background-position: left;
}

.CmpMenuTlf{
    padding: 10px 5px;
}


.HamburguerMenu{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    right: 5px;
    position: absolute;
}

.CmpMenuLogoTlf{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.HamburguerMenu{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.CmpMenuMenuTlf{
    flex-direction: column;
    font-family: Roboto-Black-900;
    font-size: 20px;
    padding-top: 30px;
    width: 100%;
}

.CmpMenuMenuTlf > div{
    padding-bottom: 15px;
}

.CmpMenuConsultoriaTlf{
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 20px;
}

.CmpMenuConsultoriaTlf > div{
    background-color: #38A3A5;
    text-align: center;
    color: white;
    font-family: Roboto-Bold-700;
    font-size: 18px;
}

.CmpQandA{
    padding: 150px 100px 30px 100px;
    margin: auto;
    background: rgb(128,237,153);
    background: linear-gradient(0deg, rgba(128,237,153,1) 70%, rgba(0,212,255,0) 100%);
    
}

.CmpQandA h2{
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 36px;
    color: #22577A;
    font-family: Roboto-Black-900;
}

.CmpQandA h3 button{
    font-size: 20px;
    font-weight: 600;
}

.accordion-body{
    text-align: left;
}

.accordion{
    padding-left: 100px;
    padding-right: 100px;
}

.accordion-item{
    background-color: rgba(0, 0, 0, 0);
    border-style: none none solid none;
}

.accordion-item:first-of-type{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.accordion-item:last-of-type{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.accordion-item:hover{
    background-color: #38A3A5;
    transition: 0.6s;
}

.accordion-button{
    background-color: rgba(0, 0, 0, 0);
}

.accordion-button:not(.collapsed){
    background-color: #38A3A5;
    color: black;
}

.accordion-button:focus{
    color: black;
    box-shadow: none !important;
}

.accordion-body{
    background-color: #E1FFEE;
}

@media (max-width: 1000px){
    .CmpQandA{
        padding-top: 20px;
        padding-right: 0px;
        padding-left: 
        0px;
    }
    .accordion{
        padding: 0px;
    }
}
.CmpDetalleTarifa{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.CmpDetalleTarifa th{
    padding: 8px;
    text-align: center;
    background-color: #22577A;
    color: white;
    border: 1px solid #ddd;
}

.CmpDetalleTarifa td{
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.CmpDetalleTarifaTitol{
    margin-top: 25px;
    margin-bottom: 10px;
}

.CmpDetalleTarifa tr:nth-child(even){background-color: #f2f2f2;}

.CmpDetalleTarifa tr:hover {background-color: #80ED99;}
.App-content-resultados {
    padding: 2rem 10%;
    padding-bottom: 0;
    min-height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.CmpTarifa-Image{
    width: 70px;
}
  
.CmpTarifa{
    display: flex;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    display: table;
    height: 100%;
    background-color: white;
    width: 100%;
}

.CmpTarifa-columna:nth-child(4){
    min-width: 146px;
}

.CmpTarifa-title{
    font-size: 12px;
}

.CmpTarifa-precio{
    font-size: 20px;
}

.CmpTarifa-Compania{
    font-size: 15px;
}

.CmpTarifa-detalle{
    font-size: 15px;
    margin-top: 5px;
    color: rgb(100, 100, 100);
    cursor: pointer;
}

.CmpTarifas{
    max-width: 900px;
    width: 100%;
    position: relative;
}

.CmpTarifas button{
    display: flex;
    position: absolute;
}

.CmpOffCanvas{
    width: 280px;
}

.CmpResultadosAdsenseIni{
    height: 150px;
}
.RellenarAuto{
  font-weight: 300;
  font-size: 15px;
  width: 98px;
  height: 25px;
  margin-top: 0px;
  background-color: #d66515d6;
}
.RellenarAuto:hover{
  background-color: #d66515fe;
  transition: 0.2s;
}

.CmpTarifaDestacado{
border: 3px solid rgba(5, 170, 0, 0.68);
}

.CmpTarifa-butons{
display: flex;
padding: 0px 15px;
vertical-align: middle;
flex-direction: column;
align-items: center;
width: 240px;
}

.CmpTarifa-butons div:first-child{
margin-bottom: 5px;
}

.CmpTarifa-columna{
padding: 0px 15px;
display: table-cell;
vertical-align: middle;
border-right: 1px solid #bbb;
width: 250px;
}

.CmpTarifa-total{
font-weight: 600;
}

.CmpTarifa.true{
  background-color: #80ED99;
}

.CmpButtonContratar{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  font-size: 18px;
  font-family: Roboto-Black-900;
  background-color: #22577A;
  color: white;
  background: linear-gradient(to left, #22577A 50%, #38A3A5 50%) right;
  background-size: 200%;
  transition: all 0.4s ease;
  cursor: pointer;
}

.CmpButtonContratar:hover{
  color: white;
  background-position: left;
}

.DetalleTarifa{
  font-size: 14px;
  background-color: #80ED99;
  color: #22577A;
  margin-right: 0px;
  height: 25px;
}

.DetalleTarifa:first-child{
  margin-right: 3px;
}

.CmpTarifaDetalleButtons{
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 1000px){
.CmpTarifa{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.CmpTarifa-columna{
    border-right: 0px;
}
.CmpTarifa-total{
  font-weight: 600;
  font-size: 30px;
}
}
.CmpFilter{
    display: flex;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    min-width: 250px;
    margin-bottom: 20px;
    display: table;
    background-color: white;
    margin-right: 10px;
}

.CmpFilterFiltros{
    display: flex;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
    height: 35px;
    cursor: pointer;
}

.CmpFilterFiltros p{
    font-size: 20px;
}

/*CmpCheckbox INI*/
.CmpFilterCheckBox{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}

.CmpFilterCheckBox h3{
    font-size: 20px;
}
.CmpFilterCheckBox label{
    font-size: 15px;
}
/*CmpCheckBox FIN*/

.CmpFilterSelect{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}
.CmpFilterSelect h3{
    font-size: 20px;
}
.CmpFilterSelect select{
    font-size: 15px;
}

.CmpOrderBy{
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3px;
    position: absolute;
    right: 0;
    top: 0;
}
.CmpNotFound{
    background-color: rgba(5,170,0,.68);
    border-radius: 25px;
    color: white;
    padding: 50px;
    margin-top: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpNotFound img{
    position: absolute;
    top: 80px;
    right: 100px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

.CmpNotFound .CmpButton{
    margin-top: 30px;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
}
