.CmpNotFound{
    background-color: rgba(5,170,0,.68);
    border-radius: 25px;
    color: white;
    padding: 50px;
    margin-top: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpNotFound img{
    position: absolute;
    top: 80px;
    right: 100px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

.CmpNotFound .CmpButton{
    margin-top: 30px;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
}