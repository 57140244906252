/* Input formulari */

.CmpInput input {
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
    padding: 1rem;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: text;
    height: 15px;
    display: flex;
    margin-bottom: 15px;
    background: transparent;
    color: black;
    background-color: white;
   }

   .CmpInput input::placeholder{
    color: black;
   }
   
   .CmpInput input:focus {
    outline: none;
    border: 1px solid rgb(27, 0, 227);
   }
  
   .CmpInput label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
   }
  
   .CmpInputError input{
     border-color: red;
   }