.CmpInfo{
    display: flex;
    flex-direction: column;
    color: rgba(51, 21, 214, 0.7);
    justify-content: center;
    min-height: 500px;
    width: 65%;
    padding: 20px;
    align-items: flex-start;
}

.CmpInfo p{
    font-size: 14px;
    font-family: Roboto-Bold-700;
    text-align: left;
    color: black;
}

.CmpInfo h1{
    font-size: 48px;
    font-family: Roboto-Bold-700;
    text-align: left;
    color: #22577A;
}

.CmpInfo h2{
    padding-top: 20px;
    font-size: 15px;
    text-align: left;
    color: white;
}

.surTitol{
    font-size: 48px;
    font-family: Roboto-Bold-700;
    text-align: left;
    margin-top: -20px;
    color: #22577A;
}

.cursorIcon{
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.clickEffect{
    position:fixed;
    box-sizing:border-box;
    border-style:solid;
    border-color:#57CC99;
    border-radius:50%;
    animation:clickEffect 0.4s ease-out;
    z-index:99999;
    }
@keyframes clickEffect{
    0%{
    opacity:1;
    width:0.5em; height:0.5em;
    margin:-0.25em;
    border-width:0.5em;
    }
    100%{
    opacity:0.2;
    width:15em; height:15em;
    margin:-7.5em;
    border-width:0.03em;
    }
}

@media (max-width: 1000px){
    .CmpInfo{
        width: 100%;
        align-items: center;
        padding-bottom: 50px;
        min-height: auto;
        padding-top: 100px;
    }
}