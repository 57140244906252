.App {
  text-align: center;
  background-color: #FCFDFF;
  height: 100vh;
  font-family: Roboto-Regular-400;
}

.HomePage{
}

.App-content {
  padding: 4rem 10%;
  padding-bottom: 0;
  padding-top: 200px;
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/*Columnes formulari*/
.CmpForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  justify-content: center;
  padding: 20px;
  width: 35%;
  position: relative;
}

/*Global del formulari*/
.CmpFormGlobal{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 350px;
  align-items: center;
  position: relative;
  border-radius: 25px;
}

.iconoFlama{
  position: absolute;
  right: 32%;
  top: -10px;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@media (max-width: 1450px){

  .iconoFlama{
    position: absolute;
    right: 15%;
    top: -10px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

}


@media (max-width: 1450px){
  .CmpFormPetit{
      width: 90%;
  }

}

@media (max-width: 1000px){

  /*Pagina principal INI*/
  .App-content{
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 0;
  }

  .CmpFormGlobal{
    flex-direction: column;
    height: auto;
  }

  .CmpFormGlobal form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CmpButton{
    margin-bottom: 10px;
  }

  .CmpVideo iframe{
    width: 80%;
    max-width: 560px;
  }

  .iconoFlama {
    right: 10px;
  }
  /*Pagina principal FIN*/
}


