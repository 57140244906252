.CmpDetalleTarifa{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.CmpDetalleTarifa th{
    padding: 8px;
    text-align: center;
    background-color: #22577A;
    color: white;
    border: 1px solid #ddd;
}

.CmpDetalleTarifa td{
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.CmpDetalleTarifaTitol{
    margin-top: 25px;
    margin-bottom: 10px;
}

.CmpDetalleTarifa tr:nth-child(even){background-color: #f2f2f2;}

.CmpDetalleTarifa tr:hover {background-color: #80ED99;}