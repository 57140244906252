 /* Select formulari */

 .CmpSelect label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
  }
  
  .CmpSelect select{
    padding: 0;
    height: 35px;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-bottom: 15px;
    background-color: white;
    color: black;
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
  }
  
  .CmpSelect select:focus{
    outline-color: #9ED996;
    border-color: #9ED996;
  }

  .CmpSelect option{
    /*background-color: rgba(5, 170, 0, 0.68);*/
    background-color: white;
    color: black;
  }

  .CmpSelectError select{
    border-color: red;
  }